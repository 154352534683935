.m-sitemap {
    padding: 0 1rem;
    .breadcrumbs {
        margin: 16px 0;
        padding: 0 1rem;
    }
    .m-sitemap__title {
        text-align: center;
        padding: 1rem 0;
    }
    ul,li {
        list-style-type: none;
        padding: 0;
    }
    &.sitemap {
        padding: 0 1rem;
    }
    .sitemap__sub-menu--level-1 {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        color: #888787;
        text-transform: capitalize;
        @media screen and (min-width: 768px) {            
            margin: 0 -16px;
        }
    }    
    .sitemap__item--level-2 {
        width: 100%;
        margin-bottom: 1rem;
        text-transform: capitalize;
        color: #888787;
        @media screen and (min-width: 768px) {            
            width: 50%;
            max-width: 50%;
            flex-basis: 50%;
            padding: 0 1rem;
        }
        @media screen and (min-width: 1025px) {            
            width: 25%;
            max-width: 25%;
            flex-basis: 25%;
        }
    }    
    .sitemap__item--level-3 {
        .c-link {
            font-size: .75rem;
            font-weight: 400;
            line-height: 1;
            margin: 0.1rem 0;
            padding-bottom: 2px;
            padding-top: 2px;
            display: inline-block;
            text-transform: capitalize;
            color: #888787;
        }
    }
    .m-sitemap__category-title {
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.5rem;
        text-transform: uppercase;
        padding: 1rem 0;
        border-bottom: 2px solid #888787;
        margin-bottom: 1rem;
        color: #888787;
    }
    .c-toggler__title {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.75rem;
    }
}

[data-tab-info] {
    display: none;
}

.active[data-tab-info] {
    display: block;
}

.sitemap-tab-content {
    margin-top: 1rem;
    padding-left: 1rem;
    font-family: sans-serif;
    font-weight: bold;
    color: rgb(0, 0, 0);
}

.sitemap-tabs {
    border-bottom: 1px solid grey;
    background-color: rgb(156, 158, 156);
    color: rgb(0, 0, 0);
    display: flex;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
}
.sitemap-heading {
    font-size: 1rem;
    font-weight: normal;
    background-color: rgb(214, 212, 212);
    color: rgb(233, 109, 109);
    margin: 1rem 0;
    text-align: center;
}
.sitemap-tabs span {
    background: rgb(226, 229, 226);
    padding: 5px;
    border: 1px solid rgb(255, 255, 255);
    font-size: 0.9rem;
    font-weight: normal;
    text-transform: uppercase;
}

.sitemap-tabs span:hover {
    background: rgb(185, 184, 184);
    cursor: pointer;
    color: rgb(11, 11, 11);
}

ul.sitemap__tabs--list {
    display: flex;
    background: #e7e7e7;
    flex-wrap: wrap;
    .sitemap__tab--item {
        padding: 5px;
        border: 1px solid $color-grey-4;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
        flex: 0 0 25%;
        text-align: center;
        color: $color-grey-6;
        &.active {
            color: $color-black;
        }
        & > a {
            display: block;
            height: 100%;
        }
        @include media-query(small) {
            flex: 0 0 50%;
        }
    }
}
